<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="add-lesson"
      ref="add-lesson"
      centered
      title="Vertically Centered"
      hide-footer
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Domain"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.id"
                  placeholder="domain"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="update_record.isUpdate"
              cols="12"
            >
              <b-form-group
                label="Support service"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="newRecord.supportedServices"
                  :options="supportedServices"
                  name="flavour-1"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addNewRecord"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- input search -->
    <b-row class="mb-2">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-md-0"
      >
        <div class="custom-search d-flex justify-content-center">
          <b-form-group>
            <div class="mb-1">
              <b-button
                v-b-modal.add-lesson
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Back') }}</span>
              </b-button>
            </div>
            <div class="">
              <b-button
                v-b-modal.add-lesson
                variant="primary"
                @click="resetForm"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Add Domain') }}
              </b-button>

            </div>

          </b-form-group>

        </div>

      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      style-class="vgt-table bordered striped"
      :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'displayName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.displayName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'isVerified'">
          <b-badge :variant="props.row.isVerified ? statusVariant('Active') : statusVariant('Pending')">
            {{ props.row.isVerified ? 'Active' : 'Pending' }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.add-lesson
                @click="openFormUpdate(props)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteRecord(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormCheckboxGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
    name: 'List',
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormCheckboxGroup,
    },
    data() {
        return {
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'id',
                },
                {
                    label: 'Total User',
                    field: 'total_user',
                },
                {
                    label: 'Supported Services',
                    field: 'supportedServices',
                },
                {
                    label: 'Is verified',
                    field: 'isVerified',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [],
            newRecord: {
                id: '',
                supportedServices: [],
            },
            update_record: {
                isUpdate: false,
                id: '',
            },
            supportedServices: [
                { text: 'Email', value: 'Email' },
                { text: 'Sharepoint', value: 'Sharepoint' },
                { text: 'EmailInternalRelayOnly', value: 'EmailInternalRelayOnly' },
                { text: 'OfficeCommunicationsOnline', value: 'OfficeCommunicationsOnline' },
                { text: 'SharePointDefaultDomain', value: 'SharePointDefaultDomain' },
                { text: 'FullRedelegation', value: 'FullRedelegation' },
                { text: 'SharePointPublic', value: 'SharePointPublic' },
                { text: 'OrgIdAuthentication', value: 'OrgIdAuthentication' },
                { text: 'Yammer', value: 'Yammer' },
                { text: 'Intune', value: 'Intune' },
            ],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active : 'light-success',
                Pending     : 'light-danger',
                /* eslint-enable key-spacing */
            }

            return (status) => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getListUser()
    },
    methods: {
        async getListUser() {
            this.$swal_show()
            this.rows = []
            await this.$http.post('/third-party/list-domain')
                .then(async (response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        for (let i = 0; i < data.value.length; i++) {
                            data.value[i].total_user = (await this.getListUserInDomain(data.value[i].id))
                        }
                        this.rows = data.value
                        this.$swal_hide()
                    } else {
                        this.$swal_hide()
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    this.$swal_hide()
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async getListUserInDomain(domain_id) {
            const response = await this.$http.post('/third-party/list-user-in-domain', { domain_id })
            if (response.data.success) {
                // console.log(response.data.data.value.length)
                return response.data.data.value.length
            }
            return 0
        },
        resetForm() {
            this.newRecord = {
                id: '',
                supportedServices: [],
            }
        },
        hideModal(modalID) {
            this.$refs[modalID].hide()
        },
        openFormUpdate(props) {
            this.update_record.isUpdate = true
            this.update_record.id = props.row.id
            this.newRecord = {
                id: props.row.id,
                supportedServices: props.row.supportedServices,
            }
        },
        async addNewRecord() {
            this.$swal_show()
            if (this.update_record.isUpdate) {
                await this.updateRecord(this.update_record.id, this.newRecord)
            } else {
                await this.$http.post('/third-party/add-domain', this.newRecord)
                    .then(async (res) => {
                        const { data, message, success } = res.data
                        if (success) {
                            await this.getListUser()
                            this.hideModal('add-lesson')
                            this.$swal({
                                title: 'Good job!',
                                text: 'Add new lesson success!',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__tada',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            this.$error(message)
                        }
                    }).catch((error) => {
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            }
            this.$swal_hide()
        },
        async deleteRecord(record) {
            await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.$swal_show()
                    await this.$http.post('/third-party/delete-domain', { domain_id: record.id })
                        .then(async (res) => {
                            const { data, message, success } = res.data
                            if (success) {
                                await this.getListUser()
                                this.$success('Delete success')
                            } else {
                                this.$error(message)
                            }
                        }).catch((error) => {
                            const status = _.get(error, 'response.status', 'unknown')
                            const reason = _.get(error, 'response.statusText', 'unknown')
                            const message = _.get(error, 'response.data.message', 'unknown')

                            if (_.isArray(message)) {
                                message.forEach((msg) => {
                                    this.$error(msg)
                                })
                            } else {
                                this.$error(message)
                            }
                        })
                    this.$swal_hide()
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async updateRecord(id, update) {
            await this.$http.post('/third-party/update-domain', { domain_id: id, domain: update })
                .then(async (res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.update_record.isUpdate = false
                        this.update_record.id = ''
                        await this.getListUser()
                        this.hideModal('add-lesson')
                        this.$success('Update success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.back-btn {
  margin-bottom: 14px;
}
</style>
